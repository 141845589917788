.roleTableActions {
    display: flex;
}

.deleteRoleModal {
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    padding: 10px;
}

.roleModal {
    padding-bottom: 24px;
}

.saveRoleConfigs {
    text-align: right;
    padding: 10px;
    z-index: 1;
    bottom: 0;
    position: absolute;
    width: 96%;
    background-color: #FFF;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}