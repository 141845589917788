.exportCSVCount{
    font-weight: lighter;
    color: rgba(0, 0, 0, 0.6);
    .exportCSVCountNumber{
        color: rgba(0, 0, 0, 0.87);
         font-weight:normal;
    }
}
.export-csv-api-columns-p{
    margin: 0 0 5px;
}
.export-csv-buttons-div{
    text-align: "end";
}
.export-csv-buttons-grid{
    column-gap: 20px;
}
.export-csv-select{
    min-width: 200px
}
