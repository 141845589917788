@import "./../../variables.scss";

.single_chart{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 350px;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
    border: 1px solid #BFCDD9;
    background: map-get($ford-element-color, 000);
}

.activeDepots {
    padding: 20px 10px;
    height: 98%;
    display: block;
}

.containerCount {
    margin: 0;
    font-size: 24px;
    color: map-get($ford-font-colors, 1);
}

.valueChangeContainer {
    margin: 0;
    font-size: 14px;
    color: map-get($ford-element-color, 1200);
}

.zeroChange {
    margin: 0 5px;
    font-size: 14px;
    color: map-get($ford-element-color, 1200);
}

.rdrDateRangePickerWrapper{
    // border: .5px solid black;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
 }

.home_Page_header{
    padding: 10px;

    #homePageFilter {
        border: 1px solid map-get($ford-action-color, default-1);
        padding: 4px 10px;
        color: map-get($ford-action-color, default-1);
    }

    .homePageFilterIcon {
        color: map-get($ford-action-color, default-1);
    }
}

.homePageCard {
    padding: 5px 10px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
    border: 1px solid #BFCDD9;
    border-radius: 4px;
    background: map-get($ford-element-color, 000);

    .homePageCardContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 5px;

        .centerAligned {
            display: flex;
            align-items: center;
        }

        .homePageCardLink {
            text-decoration: none;
            color: map-get($ford-other-color, blue);
        }
    }
}

.infoIcon {
    padding-left: 5px;
    color: map-get($ford-element-color, 1200);
}

.homePageCardTitle {
    margin: 0;
    font-size: 16px;
    color: map-get($ford-other-color, gray);
}

.homePageCardCount {
    margin: 0;
    font-size: 24px;
}

.billingCard {
    padding-bottom: 20px;
    border-bottom: 1px dashed map-get($ford-element-color, 400);
}

.billingCardTitle {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
    color: map-get($ford-other-color, gray-2);
}

.billingChildren {
    display: flex;
    align-items: center;
    padding-top: 5px;
}

.arrowCircleUpIcon {
    color: map-get($ford-other-color, green-1);
    margin: 0 5px;
}

.arrowCircleDownIcon {
    color: map-get($ford-action-color, red-color);
    margin: 0 5px;
}

.positiveChange {
    .value {
        color: map-get($ford-other-color, green-1);
    }
    .text {
        color: map-get($ford-element-color, 1200);
    }
}

.negativeChange {
    .value {
        color: map-get($ford-action-color, red-color);
    }
    .text {
        color: map-get($ford-element-color, 1200);
    }
}

.chargingStatsContainer {
    padding: 20px;
    border: 1px solid #BFCDD9;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
    margin-right: 0;
    align-items: center;
    background: map-get($ford-element-color, 000);

    .chargingStatsTitle {
        padding-left: 5px;
        font-size: 24px;
        color: map-get($ford-font-colors, 1);

        .allTime {
            color: map-get($ford-element-color, 1200);
        }
    }

    .noTopMargin {
        margin-top: 0;
    }

    .chargingStatDivider {
        color: "#D6D7D3";
        width: 100%;
        margin: 15px 0 5px 0;
    }

    .dateContainer {
        justify-content: flex-end;
        align-items: center;

        .fromToDates {
            display: flex;
            column-gap: 10px;
            justify-content: flex-end;
        }
    }

    .innerContainer {
        display: flex;
        justify-content: space-between;
        border: none;
        box-shadow: none;
        padding: 0;

        .innerContainerItem {
            width: 49%;
            padding: 10px;
            border-radius: 8px;
            border: 1px solid rgba(100, 100, 111, 0.2);
            height: inherit;
        }
    }
}

.chargingStatsTooltip {
    width: auto;
    height: auto;
    border: 1px solid #999999;
    background-color: #fefefe;
    padding: 5px;

    .tooltipLabel {
        color: #8884d8;
    }
 }

 .portsContainer {
    padding: 20px;
    height: 360px;
    display: block;
 }

.analyticsMapContainer {
    padding: 20px;
    margin-bottom: 10px;
    height: 600px;
    border-radius: 8px;
    border: 1px solid #BFCDD9;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
    background: map-get($ford-element-color, 000);
}

.analyticsMap {
    height: 100%;

    .mapTitle {
        color: map-get($ford-other-color, gray);
    }

    .centerAligned {
        display: flex;
        align-items: center;

        .markerLegend {
            margin: 0;
            font-size: 14px;
            color: map-get($ford-other-color, gray);
        }
    }

    .noLinkUnderline {
        text-decoration: none;
    }
 }

.chargerGraphsPadding {
    padding: 0 0 0 16px !important;
}

.chargerContainers {
    padding: 30px;
    height: 350px;
    display: block;
}

 .chargerTypeGraph {
    height: 100%;
 }

 .chargingModeGraph {
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 10px;
 }

.chargingModeTooltip {
    background-color: map-get($ford-element-color, 000);
    padding: 5px;
    border: 1px solid #cccc;
 }

.graphTooltip {
    margin: 0;
    color: map-get($ford-other-color, gray);
    font-size: 16px;
}

.graphLegendKey {
    color: map-get($ford-other-color, gray);
    font-size: 16px;
}

.graphLegendValue {
    color: map-get($ford-font-colors, 1);
    font-size: 16px;
}

.analyticsPage {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dataUpdation {
        margin: 0;
        color: map-get($ford-element-color, 1200);
        font-size: 14px;
    }
}