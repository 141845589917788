@import "./variables.scss";

// Font Sizes Start
// map-get($ford-primary-color, 1)
.display_four {
  font-size: map-get($ford-font-size, font-72);
  font-weight: map-get($ford-font-weight, regular);
  line-height: normal;
  letter-spacing: 0px;
  margin: 0;
}
.display_three {
  font-size: map-get($ford-font-size, font-60);
  font-weight: map-get($ford-font-weight, regular);
  line-height: normal;
  letter-spacing: 0px;
  margin: 0;
}
.display_two {
  font-size: map-get($ford-font-size, font-48);
  font-weight: map-get($ford-font-weight, medium);
  line-height: normal;
  letter-spacing: 0px;
  margin: 0;
}
.display_one {
  font-size: map-get($ford-font-size, font-36);
  font-weight: map-get($ford-font-weight, medium);
  line-height: normal;
  letter-spacing: 0px;
  margin: 0;
}
.headline {
  font-size: map-get($ford-font-size, font-24);
  font-weight: map-get($ford-font-weight, semibold);
  line-height: 30px;
  letter-spacing: 0px;
  margin: 0;
}
.title {
  font-size: map-get($ford-font-size, font-20);
  font-weight: map-get($ford-font-weight, medium);
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0;
}
.sub_title_one {
  font-size: map-get($ford-font-size, font-16);
  font-weight: map-get($ford-font-weight, medium);
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0;
}
.sub_title_two {
  font-size: map-get($ford-font-size, font-18);
  font-weight: map-get($ford-font-weight, regular);
  line-height: normal;
  letter-spacing: 0px;
  margin: 0;
}
.body_one {
  font-size: map-get($ford-font-size, font-16);
  font-weight: map-get($ford-font-weight, regular);
  line-height: normal;
  letter-spacing: 0px;
  margin: 0;
}
.body_two {
  font-size: map-get($ford-font-size, font-14);
  font-weight: map-get($ford-font-weight, regular);
  line-height: 20px;
  letter-spacing: 0px;
  margin: 0;
}
.button_one {
  font-size: map-get($ford-font-size, font-14);
  font-weight: map-get($ford-font-weight, medium);
  line-height: 20px;
  letter-spacing: 0px;
  margin: 0;
}
.button_two {
  font-size: map-get($ford-font-size, font-14);
  font-weight: map-get($ford-font-weight, medium);
  line-height: 20px;
  letter-spacing: 0px;
  margin: 0;
}
.caption {
  font-size: map-get($ford-font-size, font-12);
  font-weight: map-get($ford-font-weight, regular);
  line-height: normal;
  letter-spacing: 0px;
  margin: 0;
}
.overline {
  font-size: map-get($ford-font-size, font-10);
  font-weight: map-get($ford-font-weight, regular);
  line-height: normal;
  letter-spacing: 0px;
  margin: 0;
}
.notification {
  font-size: map-get($ford-font-size, font-10);
  font-weight: map-get($ford-font-weight, regular);
  line-height: normal;
  letter-spacing: 0px;
  margin: 0;
}
// Font Sizes End

//Buttons Start
.btns {
  .MuiButton-contained {
    background: map-get($ford-action-color, default-1);
    color: map-get($ford-action-color, labelColor);
    padding: 8px 16px;
    border-radius: 2px;
    box-shadow: 1px 1px 5px #a3b6c8;
    &:hover {
      background: map-get($ford-action-color, hover-3);
    }
  }
  .MuiButton-outlined {
    background: #fff;
    color: map-get($ford-action-color, default-1);
    padding: 8px 16px;
    border-radius: 2px;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid map-get($ford-action-color, hover-3);
      background: #fff;
      color: map-get($ford-action-color, hover-3);
    }
  }
  .Mui-disabled {
    color: #bdbdbd;
  }
}
//Buttons End

//Status Colors
.provisioning {
  color: map-get($ford-other-color, yellow);
}
.running {
  color: map-get($ford-other-color, green);
}
.accepted {
  color: map-get($ford-primary-color, 12);
}

//Status Colors End

.MuiDialogTitle-root {
  .MuiButton-root {
    background: transparent;
    border-radius: 50%;
    .MuiButton-label {
      color: map-get($ford-element-color, 700);
    }
  }
}
.MuiTableCell-root {
  .MuiButton-root {
    background: transparent;
    border-radius: 50%;
  }
  .MuiButton-label {
    color: map-get($ford-action-color, default-1);
  }
  .login {
    background-color: map-get($ford-action-color, default-1);
    color: white;
    &:hover {
      background-color: map-get($ford-primary-color, 1);
    }
    border-radius: 0%;
  }
}

.MuiFormLabel-root.Mui-focused {
  color: map-get($ford-action-color, default-1) !important;
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: map-get($ford-action-color, default-1) !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: map-get($ford-action-color, default-1) !important;
}
.MuiCheckbox-indeterminate {
  color: map-get($ford-action-color, default-1) !important;
}

#json-pretty {
  max-height: 300px;
}

.copy_to_clipboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  border-radius: 5px;
  max-height: 600px;
  overflow: auto;
  position: relative;
  background: #dbe3ea;
  p {
    margin: 0;
  }
  svg {
    color: gray;
    font-size: 20px;
    cursor: pointer;
  }
}

.click_to_load{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  border-radius: 5px;
  max-height: 600px;
  overflow: auto;
  position: relative;
  background: #dbe3ea;
  cursor: pointer;

  p {
    color: map-get($ford-action-color, default-1);
    margin: 0;
  }
}

.copy_to_clipboard_new {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0;
    color: map-get($ford-action-color, default-1);
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  svg {
    color: #7790a5;
    font-size: 20px;
    cursor: pointer;
  }
}

.currency_autocomplete {
  .MuiFormControl-root {
    width: 100% !important;
    margin: 0 !important;
  }
}

.opentable {
  cursor: pointer;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: map-get($ford-primary-color, 12) !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: map-get($ford-primary-color, 12) !important;
}
.table_toolbar_flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .switch_btw {
    display: flex;
    .number_of {
      margin-right: 20px;
      span {
        color: map-get($ford-primary-color, 12);
      }
    }
  }
}
.table_toolbar {
  padding-left: 0 !important;
  padding-right: 0 !important;
  .searchInput {
    width: 30%;
    margin-right: 10px;
  }
  .filter {
    border: 1px solid #a3b6c7;
    padding: 4px 10px;
    color: #3c5164;
  }
  .filter_icon {
    color: #a3b6c7;
  }
}
.company_details {
  border-radius: 5px;
  margin: 0;
  padding: 5px 16px;
}
.single_details {
  margin-right: 5px;
  margin-bottom: 20px;
  .title {
    @extend .body_one;
    color: map-get($ford-primary-color, 12);
    margin-bottom: 5px;
  }
  .title_value {
    @extend .body_two;
    color: map-get($ford-other-color, gray);
  }
}

.ops_breadcrumb {
  margin: 0 16px !important;
  .breadcrumb_link {
    color: map-get($ford-primary-color, 12);
    text-decoration: none;
    @extend .button_one;
    &:hover {
      text-decoration: underline;
    }
  }
  .breadcrumb_title {
    color: map-get($ford-other-color, gray);
    @extend .button_one;
  }
  .MuiBreadcrumbs-separator {
    margin: 0 5px;
  }
}

.table-onclick-col {
  color: map-get($ford-primary-color, 12);
  cursor: pointer;
  line-height: 35px;
  &:hover {
    text-decoration: underline;
  }
}
.table-first-col {
  color: map-get($ford-primary-color, 12);
  line-height: 35px;
}

.section_title {
  @extend .headline;
  margin: 12px 0 15px 0;
}
.module_titles {
  @extend .caption;
}

.center_loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiCircularProgress-colorPrimary {
    color: map-get($ford-action-color, default-1);
  }
}

.manual_pointer {
  cursor: pointer;
}

.table_col_ellipsis {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.no_data_in_table {
  min-height: 400px;
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.table_chips {
  .MuiChip-root {
    border-radius: 4px;
    margin: 0 5px 0 0;
  }
  .more {
    background-color: map-get($ford-action-color, default-1);
    color: white;
    &:hover {
      background-color: map-get($ford-primary-color, 1);
    }
  }
}

.error-bound-main {
  text-align: center;
  height: 85vh;
  position: relative;
  .error-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    .error-img {
    }
    .error-title {
      @extend .headline;
      margin: 25px 0 15px 0;
    }
    .error-des {
      @extend .sub_title_one;
    }
  }
}

.searchWithIconWithoutBorder {
  .MuiInput-underline {
    &:before,
    &:hover,
    &:after {
      content: "";
      border-bottom: none;
    }
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    content: "";
    border-bottom: none;
  }
}
.filter-radio-btns {
  .MuiFormControl-root {
    width: 100%;
    .MuiFormControlLabel-root {
      border: 1px solid rgb(219, 227, 234);
      margin-left: 0px;
      margin-right: 0px;
      .MuiTypography-body1 {
        @extend .body_two;
      }
      .PrivateRadioButtonIcon-checked-39 {
        color: map-get($ford-action-color, default) !important;
      }
      .MuiRadio-colorSecondary.Mui-checked {
        color: map-get($ford-action-color, default) !important;
      }
    }
  }
  .custom-date-range {
    display: flex;
    margin: 12px;

    .single-date {
      width: 100%;
      padding: 0 10px;
    }
  }
}
.mui-date-picked-reports {
  #date-picker-dialog {
    padding: 14px 14px 16px 14px;
  }
}
.expiring {
  color: map-get($ford-other-color, red);
}
.update_available {
  color: map-get($ford-action-color, golden-color);
}

.up_to_date {
  color: map-get($ford-action-color, green-color);
}
.NA_or_failed {
  color: map-get($ford-action-color, red-color);
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: map-get($ford-action-color, default-1);
}

.pre_prov_depot_details,
.pre_prov_last_updated {
  p {
    @extend .caption;
    margin-bottom: 5px;
    span {
      @extend .button_two;
    }
  }
}
.pre_prov_last_updated {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-bottom: 5px;
}
.no_data_in_filter {
  text-align: center;
}

.common_component_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  .MuiCircularProgress-root {
    color: map-get($ford-action-color, default-1);
  }
}

.reports_new_section_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  p {
    @extend .sub_title_two;
    font-weight: 600;
  }
  #historyBtn {
    border: 1px solid map-get($ford-action-color, default-1);
    color: map-get($ford-action-color, default-1);
  }
  .historyIcon {
    color: map-get($ford-action-color, default-1);
  }
}
.reports_new_section_main {
  background: white;
  border: 1px solid #bfcdd9;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 20px;
  .request_title {
    @extend .body_one;
    margin-bottom: 25px;
  }
}

.errorMsg {
  margin: 0;
  @extend .body_one;
  color: map-get($ford-action-color, red-color);
}

.zeroMargin {
  margin: 0;
}
.bulkUploadSubmit {
  text-align: end;
  padding-bottom: 10px;
}

.flex_div {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex_div_space_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex_div_start {
  display: flex;
  align-items: center;
  justify-content: start;
}

.edit_charger_height {
  padding: 16px 16px 16px 16px;
  max-height: calc(100vh - 115px);
  min-height: calc(100vh - 115px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  .maxAmpsBanner {
    background: #f5f5f5;
    margin: 10px 0 0 16px;
    padding: 10px 16px;
    font-size: 16px;

    .ampsTitle {
      color: #38393f;
      font-weight: 400;
    }

    .ampsValue {
      color: #18191e;
      font-weight: 600;
    }

    .info {
      color: #18191e;
      display: flex;
      align-items: flex-start;
      column-gap: 5px;
    }

    .infoIcon {
      color: #22539b;
    }
  }
}

.charger_edit_single_field {
  // margin: 8px 0 8px 0;
  width: 100%;
}

.width100 {
  width: 100%;
}

.info_icon {
  margin: 0 10px;
  color: #6a6b71;
}

.custom_error_msg {
  display: flex;
  align-items: center;
  justify-self: unset;
  padding: 12px;
  background: #fae7e5;
  border-top: 2px solid #d01300;
  border-radius: 4px;
  .MuiSvgIcon-root {
    margin-right: 10px;
    color: #d01300;
  }
  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #38393f;
  }
}

.chargerDerateBanner {
  display: flex;
  align-items: center;
  background: #f7f8ff;
  border-top: 4px solid #22539b;
  padding: 8px;

  .leftInfoIcon {
    color: #22539b;
  }

  .rightInfoIcon {
    color: #6a6b71;
  }

  .chargerConfigUrlLabel {
    font-size: 14px;
  }

  p {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #18191e;
    padding-right: 10px;
    padding-left: 10px;

    .timestamp {
      color: #38393f !important;
      font-size: 400;
    }
  }
}

.custom_alert_msg {
  display: flex;
  align-items: center;
  justify-self: unset;
  padding: 12px;
  background: #fef8eb;
  border-top: 2px solid #f1b534;
  border-radius: 4px;
  .MuiSvgIcon-root {
    margin-right: 10px;
    color: #f1b534;
  }
  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #38393f;
  }
}

.delete_red_btn {
  background: #d01300 !important;
  border-radius: 1000px !important;
  padding: 12px 16px !important;
  color: white !important;
}

.alert_blue_btn {
  background: #0064d4 !important;
  border-radius: 1000px !important;
  padding: 12px 16px !important;
  color: white !important;
}

.linear_progress_styles {
  background-color: #b3d1f2;
  .css-5ir5xx-MuiLinearProgress-bar1 {
    background-color: #0051a2;
  }
  .css-5ir5xx-MuiLinearProgress-bar2 {
    background-color: #0051a2;
  }
}
.download_icon {
  color: #2770d8;
}

.rfidStatusInfoIcon {
  color: #6a6b71;
}

.circuit_chips {
  margin-top: 15px;
  .chip_sec {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .chipTitle {
      @extend .caption;
      margin-right: 20px;
    }
  }
  .MuiChip-filled {
    background: map-get($ford-action-color, default-1);
    color: white;
  }
}

.charger_chips_section {
  max-height: 100px;
  overflow-y: auto;
  .charger_chips {
    margin-top: 15px;
    padding-bottom: 8px;
    .chip_sec {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .chipTitle {
        @extend .caption;
        margin-right: 20px;
        margin-top: 8px;
      }
    }
    .MuiChip-filled {
      background: map-get($ford-action-color, default-1);
      color: white;
    }
  }
}

.CLLM_legend_section {
  display: flex;
  justify-content: start;
  align-items: center;

  .axis_details_cllm {
    width: 20%;
    margin-left: 50px;
    color: map-get($ford-other-color, gray);
    p {
      @extend .caption;
    }
  }

  .chart_Legends,
  .chart_ReferenceLine {
    width: 20%;
    .single_legend {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      p {
        @extend .caption;
        color: map-get($ford-other-color, gray-2);
        margin: 0 0 0 20px;
      }
    }
  }
}

.newSelect {
  .MuiFormLabel-root {
    font-size: 0.8rem;
    line-height: 1.3rem;
  }
}

.newAutoComplete {
  .MuiFormLabel-root {
    font-size: 0.8rem;
    line-height: 1.3rem;
  }
  .MuiInputBase-input {
    font-size: 0.8rem !important;
    line-height: 1.3rem !important;
  }
}

.circuit_monitor_chart {
  background: white;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 9px -2px rgba(0, 11, 100, 0.3);
  .DCP_export {
    padding: 0 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .DCP_name {
      @extend .sub_title_one;
      text-align: left;
      padding: 8px 0 12px 0;
    }
    .exportBtn {
      background: white;
      color: #3d5164;
      border-radius: 4px;
      box-shadow: 0px 0px 0px 0.5px rgba(0, 11, 100, 0.2);
      padding: 2px 5px;
      font-size: 14px;
      font-weight: 500;
      &:hover {
        background: #f0f0f0;
      }
    }
  }
}

.no_data_main {
  margin: 1rem;
  border-radius: 0.28571429rem;
  box-shadow: 0px 0px 0px 0.5px rgba(0, 11, 100, 0.2);
  min-height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  .no_data {
    .MuiSvgIcon-root {
      font-size: 30px;
    }
    p {
      @extend .sub_title_one;
      color: map-get($ford-other-color, gray-2);
    }
  }
}

.loading_main {
  margin: 1rem;
  border-radius: 0.28571429rem;
  box-shadow: 0px 0px 0px 0.5px rgba(0, 11, 100, 0.2);
  min-height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart_layout {
  margin: 0.5rem;
  border-radius: 0.28571429rem;
  box-shadow: 0px 0px 0px 0.5px rgba(0, 11, 100, 0.2);
  padding: 1em 0.5em;
  position: relative;
  height: 260px;
  .zoomInOut {
    font-size: 14px;
    color: #3c5164;
    cursor: pointer;
  }
}

.circuit_monitor {
  background: white;
  // box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin: 20px 0 30px 0;
  padding: 24px 16px 16px;
  box-shadow: 0px 0px 9px -2px rgba(0, 11, 100, 0.3);
}

.circuit_radio {
}

.custom-tooltip {
  background-color: rgba(210, 210, 210, 0.5);
  padding: 5px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  p {
    @extend .sub_title_one;
    color: map-get($ford-other-color, gray-2);
    display: flex;
    align-items: center;
  }
}

.legendsOfGraph {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .legends {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .custom-paragraph {
      position: relative;
      padding-left: 20px;
      margin: 0 20px 0 0;
      @extend .caption;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        // background-color: red;
        border-radius: 50%;
      }

      span {
        color: #3c5164;
      }
    }
    .currentOffered {
      &::before {
        background-color: #0051a2;
      }
    }
    .currentImport {
      &::before {
        background-color: #82ca9d;
      }
    }
    .powerActiveImport {
      &::before {
        background-color: #82ca9d;
      }
    }
    .powerOffered {
      &::before {
        background-color: #0051a2;
      }
    }
  }
}

.testVersionIcon {
  color: #6b7786;
}

.fab_btn {
  background-color: #0051a2;
  color: white;
  &:hover {
    background-color: #002a66;
  }
}

.activeFiltersContainer {
  display: flex;
  align-items: center;
  margin-left: 10px;

  .activeFilters {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-right: 5px;

    .closeIcon {
      font-size: 12px;
      cursor: pointer;
    }
  }

  .clearActiveFiltersBtn {
    font-size: 12px;
    cursor: pointer;
    background: transparent;
    margin-right: 5px;
    text-transform: none;
    text-decoration: underline;
  }
}

.noTextTransform {
  text-transform: none;
}

.chargerSimDetails {
  display: flex;
  column-gap: 5px;
}