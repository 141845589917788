@import './variables.scss';
@import './common.scss';
// body{
//     font-family: $ford-font-family !important;
// }

* {
    font-family: $ford-font-family !important;
}



