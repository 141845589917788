@import "../../common.scss";

#noIntegrationReportData {
    min-height: inherit;
}

.integrationReport {
    .container {
        align-items: center;
        padding: 10px;
    }
    .testSuite {
        display: flex; 
        align-items: center;
    }
    .testSuiteName {
        font-size: 18px;
         margin: 0;
    }
    .updateTestSuite {
        margin: 4px 0px;
        padding: 6px 2px;
    }
    .testCases {
        padding: 0;
        margin: 0;
    }
    .deleteTestCases {
        cursor: pointer;
        background: transparent;
        color: #1976d2;
        padding: 0;
        margin: 0;
    }
    .submit {
        display:flex; 
        justify-content:flex-end; 
        padding:10px;
    }
}

#chargerManufacturerClipboard {
    padding-left: 0;
}

.centerAligned {
    display: flex;
    align-items: center;
}

.baselineAligned {
    display: flex;
    align-items: baseline;
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
}

.downloadTemplateLink {
    text-decoration: none;
    margin-left: 10px;
    color: rgba(0, 81, 162, 1);
}

.utilityPlanSearch {
    width: 30%;
    margin: 20px 0;

    .iconCursor {
        cursor: pointer;
    }
}

.utilityPlanChip {
    max-width: fit-content;
}

.utilityRatesTemplate {
    display: flex;
    margin: 0 10px;
}

.verifiedIcon {
    color: map-get($ford-action-color, green-color);
}

.customerVisibleCharger {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.chargerModelsDrawer {
    min-width: 650px;
}

#versionBorder {
    border-bottom: 1px solid #D6D7DE;
}

.availableFirmwares {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .firmwareInfo {
        display: flex;

        .version {
            margin: 0;
            font-size: 16px;
            font-weight: 600;
            color: #2B3B49;
        }

        .uploadTime {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            color: #3C5164;
        }
    }

    .firmwareActions {
        display: flex;
        align-items: center;

        .convertVersionBtn {
            color: #0064D4;
            cursor: pointer;
        }

        .deleteFirmwareBtn {
            color: #C83E32;
            cursor: pointer;
        }
    }
}

.endTextAligned {
    text-align: end;
}
.History-button{
    text-align: right;
    padding-bottom: 8px;
}

.uploadFirmwareBtns {
    display: flex;
    justify-content: right;
    column-gap: 10px;
    margin-top: 20px
}

.uploadFirmwareBtnBg {
    background: #0064D4;
}

.removeFirmwareList {
    height: 150px;
    overflow-y: scroll;
}

.boldFont {
    color: #18191E;
    font-weight: bold;
    font-size: 16px;
}

.firmwareDividers {
    width: 100%;
    margin: 5px 0;
}

.firmwareStepInfo {
    color: #57585D;
    font-size: 16px;
}

.switchBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #D6D7DE;
    border-radius: 4px;
    background: #F5F5F5;
    margin: 8px;
    padding-left: 10px;
    padding-right: 10px;

    .whitelabelTitle {
        margin-bottom: 0;
    }

    .whitelabel {
        font-size: 14px;
        color: #3c5164;
        margin-top: 0;
        margin-bottom: 8px;
    }

    p {
        margin: 8px;
    }
}

#rfidAuthModeContainer {
    width: 95%;
    margin: 15px;

    .rfidTable {
        padding: 10px;

        .rfidAddKey {
            margin-top: 10px;
        }
    }
}
#rfidAuthModeContainerInchargerDetails {
    width: 97%;
    margin: 15px;

    .rfidTable {
        padding: 10px;

        .rfidAddKey {
            margin-top: 10px;
        }
    }
}

.rfidAuthModeHeading {
    background: #F7F8FF;
    padding: 10px;
    border-bottom: 1px solid #D6D7DE;
}

.rfidAuthModeRows {
    align-items: center;
    border-bottom: 1px solid #D6D7DE;
    overflow-wrap: break-word;
    p {
        padding-left: 5px;
    }
}

.rfidAuthModeKey {
    background: #D6D7DE;
}

.rowHeight {
    min-height: 56px;
}

#totalHeight {
    height: calc(100vh - 120px);
}

.soldAsMakeModel {
    background: #F0F1F8;
    margin: 5px 0;
}

.bulkUpload {
    display: flex;
    column-gap: 10px;

    .title {
        padding: 10px;
        color: rgba(24, 25, 30, 1);
    }
    
    .previewContainer {
        background: white;
        width: 70%;
        border: 1px solid rgba(191, 205, 217, 1);
        box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.06);

        .previewInner {
            padding: 10px;

            .heading {
                margin: 0;
                color: rgba(24, 25, 30, 1);
                font-family: Ford Antenna;
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
            }

            .subHeading {
                margin: 5px 0;
                color: rgba(87, 88, 93, 1);
                font-family: Open Sans;
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;

            }
        }
    }

    .instruction {
        background: white;
        border: 1px solid rgba(191, 205, 217, 1);
        box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.06);

        .paddingGrp {
            padding-left: 10px;
            padding-bottom: 10px;
        }
    }

    .bulkUploadValidate {
        display: flex;
        column-gap: 10px;
        justify-content: end;
        padding: 0 10px 10px 0;
    }

    .validationMsg {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.csvButton {
    background: rgba(7, 60, 136, 1);
    color: rgba(239, 242, 245, 1);

}

.bulkUploadSteps {
    color: rgba(87, 88, 93, 1);
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
}

.bulkErrorCodeInstructions {
    .fieldContainer {
        background: rgba(240, 240, 240, 1);

        .info {
            color: rgba(51, 58, 124, 1);
            font-size: 12px;
            font-weight: 400;
        }
    }

    .labelContainer {
        display: flex;
        align-items: center;
        color: rgba(240, 240, 240, 1);

        .title {
            color: rgba(31, 48, 71, 1);
            font-size: 16px;
            font-weight: 600;
        }

        .value {
            color: rgba(107, 119, 134, 1);
            font-size: 14px;
            font-weight: 500;
        }
    }

    .chipValue {
        background: rgba(255, 255, 255, 1);
        margin: 5px;
        border-radius: 5px;
    }
}

.bulkErrorCodeReview {
    .fileNameLabel {
        color: rgba(60, 81, 100, 1);
        font-size: 16px;
        font-weight: 600;
        margin: 0;
    }

    .fileNameValue {
        color: rgba(43, 59, 73, 1);
        font-size: 16px;
        font-weight: 700;
        margin: 0;
    }

    .guideContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
        margin-bottom: 10px;

        .label {
            display: flex;
            align-items: center;
            column-gap: 5px;
            color: rgba(24, 25, 30, 1);
            font-size: 14px;
            font-weight: 600;
            margin: 0;

            .iconColor {
                color: rgba(34, 83, 155, 1);
            }
        }
    }
}

.downloadBtns {
    color: #0051A2;
    display: flex;
    align-items: center;
}

.testVersionUpload {
    padding-left: 8px;

    p {
        display: flex;
        align-items: center;
        margin: 0;
    }
}

.availableFirmwareChips {
    color: #18191E;
    background: #F0F1F8;
    margin: 0;
}

.noReplaceVersion {
    display: flex;
    align-items: center;
    color: #57585D;
    margin: 5px 0;
    column-gap: 5px;
    font-size: 14px;
    font-weight: 600;
}

.convertToTestDialog {
    .makeModelLabel {
        font-weight: 600;
        font-size: 16px;
        color: #3C5164;
    }
    .makeModelValue {
        font-weight: 700;
        font-size: 16px;
        color: #2B3B49;
    }
    .confirmMsg {
        font-weight: 600;
        font-size: 16px;
        color: #38393F;
    }
}

.ECGroupNameExample {
    color: #434749;
    margin: 0;
    padding-left: 12px;
    font-size: 12px;
}
