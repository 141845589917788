@import "./../../variables.scss";
@import "./../../common.scss";

.depot_details_main {
  .section_title {
    @extend .sub_title_two;
    color: map-get($ford-element-color, 1100);
    margin: 0 0 5px 0;
  }
  .single_details {
    margin-right: 5px;
    margin-bottom: 20px;
    .title {
      @extend .body_one;
      color: map-get($ford-primary-color, 12);
      margin-bottom: 5px;
    }
    .title_value {
      @extend .body_two;
      color: map-get($ford-other-color, gray);
    }
  }
}

.single_plan_details_section {
  margin: 12px 12px 12px 0;
  .plan_title {
    @extend .caption;
    text-transform: uppercase;
    margin-bottom: 12px;
  }
  .plan_value {
    @extend .body_two;
    font-weight: 600;
  }
}

.leapConnectUrl {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shareLeapEmail {
  width: 100%;
  margin:10px 0;
}

.shareEmailActionBtns {
  display: flex;
  justify-content: end;
  column-gap: 10px;
}

.taxIdIcon {
  color: #7790A5;
  margin-bottom: -4px;
}