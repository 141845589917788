.userAlertFilter {
  font-size: 14px;
  margin-right: 10px;
}

.userNotificationCheckbox {
  margin-left: 0;
}

.flexDisplay {
  display: flex;
}

.topPadding {
  padding-top: 20px;
}

#topMargin {
  margin-top: 8px;
}

.userSettingsContainer {
  border-radius: 4px;
  border: 1px solid #bfcdd9;
  background: #fff;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 10px;
}

#userSettingsSection {
  padding: 10px;
  justify-content: space-between;

  .centerAligned {
    align-items: center;
  }

  .flexCenterDisp {
    display: flex;
    justify-content: center;
  }

  .infoIcon {
    color: #7790a5;
    padding-left: 5px;
  }

  .categoryLabel {
    font-family: "Ford Antenna";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #22539b;
  }

  .categoryLabelSecondary {
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #38393f;
  }

  .categorySublabel {
    font-family: "Ford Antenna";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: #0051a2;
  }

  .severityLabel {
    display: flex;
    align-items: center;
  }

  .severityLevels {
    color: #38393f;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .chargerAlertsLabel {
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #38393f;
  }
}

#alertTitle {
  margin-top: 8px;
}

.userAlertSubmit {
  width: 100%;
  text-align: end;
  padding: 10px 0;
}
