@import "./../../variables.scss";
@import "./../../common.scss";

.home_Page{
    .kpi_card{
        .MuiCard-root{
            border-left: 5px solid map-get($ford-primary-color, 12 );
            .MuiCardActionArea-root{
                .MuiCardContent-root{
                    padding: 10px;
                    .card_inside_content{
                        text-align: center;
                        .count{
                            @extend .display_one;
                            color: map-get($ford-primary-color, 12 );
                        }
                        .kpi_title{
                            @extend .button_one;
                        }
                        .insights_ptag{
                            margin:4px 0px 0px 2px;
                            font-size:12px ;
                            opacity: 0.6;
                        }
                    }
                }
               
            }
            .MuiCardActions-root{
                display: flex;
                justify-content: flex-end;
                padding: 0;
                background: map-get($ford-primary-color, 11 );
                .MuiIconButton-root{
                    padding: 5px;
                    color: map-get($ford-primary-color, 12 );
                }
            }
        }
    }
}