@import "../../variables.scss";

.companiesDepotCount {
    padding: 4px 20px 4px 10px;
    color: map-get($ford-other-color, gray);
}

#companiesDepotName {
    display: flex;
    align-items: center;
}

.homeChargingIcon {
    margin-left: 5px;
}

#capitalizeText {
    text-transform: capitalize;
}

#companiesExportCSV {
    border: 1px solid #A3B6C7;
    padding: 4px 10px;
    margin: 0px 10px;
    color: map-get($ford-other-color, gray);
}
