@import "../../common.scss";
@import "../../variables.scss";

#vehicleExportCSV {
    border: 1px solid #A3B6C7;
    padding: 4px 10px;
    margin: 0px 10px;
    color: map-get($ford-other-color, gray);
}

#vehicleDiscoveryFilter {
    border: 1px solid #2770D8;
    padding: 4px 10px;
    color: #3C5164;
}

.filterIcon {
    color: map-get($ford-other-color , blue);
}

.discoveryAttempt {
    color: map-get($ford-action-color, default);
    margin-left: 10px;
}

.makeModelPower {
    display: flex;

    .make {
        margin: 4px 10px 0 0;
    }

    .powerType {
        background: map-get($ford-primary-color, 11);
        color: map-get($ford-action-color, default);
    }
}

.wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.lwrap {
  margin-right: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.rwrap {
  margin-right: 8px;
  margin-left: auto;
  justify-content: space-between;
  flex-grow: 1;
}

.chipStyle {
    background: map-get($ford-primary-color, 11);
    color: map-get($ford-action-color, default);
    margin-top: 10px;
}


// Datepicker

.dateContainer {
    justify-content: flex-end;
    align-items: center;

    .fromToDates {
        display: flex;
        column-gap: 10px;
        justify-content: flex-end;
    }
}

.fault_num_and_select {
  display: flex;
  align-items: center;

  p {
    margin-top: -15px;
    margin-right: 10px;
    font-size: 1.4rem;
    @extend .display_one;
  }

  .psub {
      margin-top: -10px;
      font-size: 1rem;
  }
}

.chartPosition {
  margin-right: 15px; 
  position: relative; 
  bottom: -10px;
}

.metrics_titles {
    color: map-get($ford-other-color, gray);
    font-size: 1rem;
}

.metrics_description {
    margin-top: -10px;
    margin-left: 25px;
    color: map-get($ford-other-color, gray);
    font-size: 0.8rem;
}

.title_for_checkList {
    display: flex;
    align-items: flex-start;
}


.success {
    position: relative;
    top: 3px;
    margin-right: 5px;
    color: #1c7e50;
  
    .successIconColor {
        color: #1c7e50;
    }
}

.error {
    position: relative;
    top: 3px;
    margin-right: 5px;
    color: #c83f32;
  
    .errorIconColor {
        color: #c83f32;
    }
}

.title_for_chargersCount {
    display: flex;
    align-items: flex-start;
    margin-top: -10px;
    justify-content: space-between;
    .MuiSvgIcon-root {
      margin-left: 5px;
      font-size: 20px;
      margin-bottom: -2px;
      color: map-get($ford-element-color, 1200);
    }
}

.title_for_successRate {
  display: flex;
  align-items: flex-start;
  .MuiSvgIcon-root {
    margin-left: 5px;
    font-size: 20px;
    margin-bottom: -2px;
    color: map-get($ford-element-color, 1200);
  }
}

.title_for_charts {
  display: flex;
  align-items: flex-start;
  margin-top: -10px;
  .MuiSvgIcon-root {
    margin-left: 5px;
    font-size: 20px;
    margin-bottom: -2px;
    color: map-get($ford-element-color, 1200);
  }
}

.chart-description {
  display: block;
  margin-top: -16px;
  width: 100%;
}

.connectivity_section {
    background-color: #ffffff;
    min-height: 300px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 21px 24px;
    .connectivity_section_loader {
      min-height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .connectivity_chart {
      width: 100%;
      height: 230px;
      margin: 20px 0 5px 0px;
    }
    .axis_details {
      padding-left: 50px;
      color: map-get($ford-other-color, gray);
      p {
        @extend .caption;
      }
    }
}

.filters_with_vertical_tab {
    width: 800px;
  
    .MuiTabs-vertical {
      min-width: 40%;
      max-width: 50%;
      height: calc(100vh - 115px);
      .MuiTab-root.Mui-selected {
        color: map-get($ford-primary-color, 2);
        background: map-get($ford-primary-color, 6);
      }
      .css-10d9dml-MuiTabs-indicator {
        width: 6px;
        background-color: map-get($ford-primary-color, 2);
      }
      .MuiTab-textColorPrimary {
        @extend .sub_title_two;
        color: map-get($ford-element-color, 900);
        font-weight: 500;
        align-items: flex-start;
        min-height: unset;
        justify-content: space-between;
      }
    }
    .filter_action_btn {
      display: flex;
      justify-content: right;
      align-items: center;
      height: 60px;
      padding: 0 16px;
      background: #ffffff;
      border-top: 1px solid #d6d7de;
      box-shadow: 0px -2px 8px rgba(78, 78, 78, 0.25),
        0px 4px 4px rgba(0, 0, 0, 0.25);
      column-gap: 10px;
  
      .btn_font_size {
        font-size: 14px;
      }
    }
    .filter_tab_vertical_body {
      padding: 16px 16px 0 16px;
      max-height: calc(100vh - 115px);
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

.chargerUptimeBarGraph {
height: 250px !important;
}

#graphAxes {
    font-size: 11px !important;
    color: #3c5164 !important;
}

.monthlyChargerUtilization {
    .monthlyTitle {
      margin: 16px 0 0 0;
    }
  
    .value {
      font-size: x-large;
      margin-top: -15px;
      margin-bottom: 10px;
    }
}


.modelSuccessTitle {
    display: flex;
    justify-content: space-between;
    
    .monthlyTitle {
      margin: 16px 0 0 0;
    }
  
    .value {
      margin: 0;
    }
}


.no_data {
    text-align: center;
}

.noDataMargin {
    margin: 10px;
}

.filter_dot {
    font-size: 10px !important;
    color: #c7b300 !important;
    margin-top: 7px !important;
}

.axis_details {
    padding-left: 20%;
    color: map-get($ford-other-color, gray);
    p {
      @extend .caption;
    }
}

.info {
  position: relative;
  top: 2px;
  margin-left: 5px;

  .infoIconColor {
      color: #7790A5;
  }
}

.tableRow {
  cursor: pointer;
}

.sessionInfo {
  cursor: pointer;
  color: blue;
}

.dividerVertical {
  margin-right: -1px;
}

.dividerHorizontal {
  width: 100%;
}

.title_for_pie_charts {
  display: flex;
  align-items: flex-start;
  margin-top: -10px;

  .MuiSvgIcon-root {
      margin-left: 5px;
      font-size: 20px;
      margin-bottom: -2px;
      color: map-get($ford-element-color, 1200);
  }
}

.graphLegendKey {
  color: map-get($ford-other-color, gray);
  font-size: 16px;
}

.graphLegendValue {
  color: map-get($ford-font-colors, 1);
  font-size: 16px;
}

.checkVinVid {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.checkVinVid_section {
  background-color: #ffffff;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 5px;
  .checkVinVid_section_loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.centerAligned {
  display: flex;
  align-items: center;
}