.ratePlansList {
    display: flex;
    width: 100%;

    .ratePlan0 {
        margin: 8px 0px 0px 5px;
        font-size: 15px;
    }

    .ratePlan1 {
        margin-left: 5px;
        font-size: 14px;
        padding-bottom: 10px;
    }

    .ratePlanSubmit {
        padding-top: 10px;
    }

    .nonBillable {
        text-align: center;
        margin-top: 5px;
        font-weight: 100;
        color: grey;
        padding-bottom: 10px;
    }
}

.requestsGrid {
    margin: 10px 0px 20px 0px;
    border: 1px solid rgba(0, 0, 0, 0.12);

    .title {
        margin-left: 5px;
        font-weight: 200;
        padding: 5px 0;
    }

    
}

.newBillingPkg {
    width: 500px;

    .depotNameKey {
        margin: 0;
        font-size: 12px;
        font-weight: 200;
    }

    .depotNameValue {
        margin: 0;
    }

    .portsKey {
        margin: 0px;
        font-size: 12px;
        font-weight: 200;
        text-align: right;
    }

    .portsValue {
        margin: 0;
        text-align: right;
    }

    .actionBtns {
        display: flex;
        padding-top: 10px;
    }

    .divider {
        padding: 10px 0px;
    }
}

#capitalizeText {
    text-transform: capitalize;
}

.ReviewAndConfirmForm {
    padding: 20px 0;

    .leftMargin {
        margin-left: 10px;
    }

    .checkBox {
        margin-left: 7px;
    }

    .actionButtonGroup {
        display: flex;
        justify-content: flex-end;
        column-gap: 5px;
    }
}

#completeWidth {
    width: 100%;
    margin: 0;
}

.bottomMargin {
    margin-bottom: 20px;
}
.delete_pop_buttonAlign{
    margin-left: 10px;
}

.duplicateDepot{
    color:red !important;
    font-weight: bold !important;
}
#configCheckboxGroup{
    margin-bottom: 0;
    margin-top: 10px;
}