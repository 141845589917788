@import "../../variables.scss";

.reportHistoryTable {
    text-align: center;
}

.reportHistoryCol {
    max-height: 120px;
    overflow-y: scroll;
}
  
.checkBoxIndeterminate {
    color: map-get($ford-action-color, default);
}

#requestedByFilter {
    width: 100%;
    margin-top: 5px;
}

#completeWidth {
    width: 100%;
}

.reportTypes {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selectedDepotsCount {
    font-size: 20px;
    margin: 0 0 0 20px;
}

.carbonCreditReport {
    margin: 5px 0px;
    width: 97%;
}

.chargerEnergyDispensed {
    margin: 5px 0px;
    width: 97%;
}

.generateReports {
    display: flex;
    align-items: center;
}
.selectDepotandAccountButton{
    border: 2px solid #2770D8 !important;
    color: #2770D8 !important;
    width: auto;
}

.bulb {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .Successful {
    background-color: green;
  }
  
  .Processing {
    background-color: blue;
  }
  
  .Failed {
    background-color: red;
  }
