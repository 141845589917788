
@font-face {
  font-family: 'Ford Antenna';
  src: url('../fonts/Ford-Antenna-All/FordAntennaWGL-Medium.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ford Antenna';
  src: url('../fonts/Ford-Antenna-All/FordAntennaWGL-Regular.woff')
    format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ford Antenna';
  src: url('../fonts/Ford-Antenna-All/FordAntennaWGL-Semibold.woff')
    format('woff');
  font-weight: 600;
  font-style: normal;
}


