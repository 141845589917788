@import "./../../variables.scss";

.table {
  overflow-x: auto;
  .MuiTable-root {
    min-width:  100%;
    width: max-content;
    
    .MuiTableHead-root {
      .MuiTableRow-root {
        th {
          background-color: map-get($ford-primary-color, 11);
          color: map-get($ford-primary-color, 12);
          font-size: map-get($ford-font-size, font-16);
          font-weight: map-get($ford-font-weight, regular);
          line-height: 24px;
          padding: 10px 16px;
          border-bottom: 1px solid #a3b6c8;
        }
      }
    }
  }
}

.pagination {
  .MuiTablePagination-caption {
    color: map-get($ford-element-color, 1200);
    font-weight: map-get($ford-font-weight, regular);
    font-size: map-get($ford-font-size, font-12); 
    line-height: 14px;
  }
  .MuiTablePagination-actions {
    .MuiIconButton-colorInherit {
      color: map-get($ford-element-color, 1200);
    }
    .Mui-disabled {
      color: #bdbdbd;
    }
  }
  .MuiTablePagination-input {
    margin-left: 0;
    border-bottom: 1px solid map-get($ford-element-color, 1200);
    font-size: map-get($ford-font-size, font-12);
    .MuiTablePagination-select {
      padding-left: 5px;
      padding-bottom: 5px;
    }
  }
  .MuiTablePagination-selectIcon {
    color: map-get($ford-element-color, 1200);
  }
}
