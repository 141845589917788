@import "../../variables.scss";

#centerAligned {
    display: flex;
    align-items: center;
}

.iconMargin {
    margin-left: 5px;
}

.companyDepotButton {
    display: flex;
    margin-right: 20px;
    column-gap: 10px;
}

.billingHistoryToolbar {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
}

.billingHistoryTableContainer {
    padding: 20px;
}

.billingHistoryDownload {
    text-decoration: none;
}

.postUsageStatus {
    padding: 10px;
}

.countTableCell {
    text-decoration: none;
    color: map-get($ford-primary-color, 12);
    cursor: pointer;
}

.editDepotPlan {
    width: 700px;
}

.depotPlanDetails {
    width: 500px;
}

.depotPlanGrids {
    margin: 10px 0 20px 0;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.depotPlanTitle {
    margin: 0 0 0 5px;
    font-weight: 200;
    padding: 5px 0;
}  

.ratePlansList {
    display: flex;
    width: 100%;

    .ratePlan0 {
        margin: 5px 0 0 5px;
        font-size: 15px;
    }

    .ratePlan1 {
        margin: 0 0 0 5px;
        font-size: 14px;
        padding: 0 0 10px 0;
    }

    .ratePlanSubmit {
        padding-top: 10px;
    }
}

.nonBillable {
    text-align: center;
    margin: 5px 0 0 0;
    font-weight: 100;
    color: grey;
    padding: 0 0 10px 0;
}

#billingExportCSV {
    border: 1px solid #A3B6C7;
    padding: 4px 10px;
    margin: 0px 10px;
    color: map-get($ford-other-color, gray);
}

.billingHome {
    display: flex;
    margin-left: 20px;
    column-gap: 25px;

    .billable {
        max-width: 500px;

        .title {
            background-color: #ffffff;
            padding: 15px;

            .leftAlign {
                text-align: left;
            }

            .rightAlign {
                text-align: right;
            }
        }
    }
}

.blueColorLoading{
    margin: 0;
    color: #0051A2;
}