@import "./../../variables.scss";
.sideBar{
    .MuiList-root{
            .Mui-selected{
                background-color: map-get($ford-primary-color, 3) ;
                border-left: 8px solid map-get($ford-action-color, default);
                
            }
            .MuiListItemText-root{
                .MuiTypography-root{
                    font-size: map-get($ford-font-size ,font-16);
                    font-weight: map-get($ford-font-weight, regular );
                }
               
            }
    }
}
.ListBottomPadding{
           padding-bottom: 70px
    }
