@import "../../variables.scss";

.servicesFilterIcon {
    color: #00095B;
}

.serviceHistoryFilter {
    width: 100%;
    margin: 20px auto;
    padding: 20px 0 0 0;

    .historyFilter {
        display: flex;
        align-items: center;
    }

    .accordianDetails {
        align-items: center;
    }
}

.serviceHistoryTable {
    max-height: 700px;

    .tableHead {
        background-color: map-get($ford-primary-color, 11);
        color: map-get($ford-primary-color, 12);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding: 10px 16px;
        border-bottom: 1px solid #a3b6c8;
    }

    .viewDetailsIcon {
        color: #1976D2;
    }

    .noRecord {
        line-height: 50px;
        padding: 10px;
        color: #a73030;
    }
}

.serviceDescription {
    font-size: 16px;
    margin: 0;
}

.serviceResponseTable {
    background: #FFFFFF;
    border: 1px solid #BFCDD9;
    border-radius: 4px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
    padding: 20px;
    gap: 28px;
    margin-top: 20px;

    .responseTitle {
        color: map-get($ford-element-color, 900);
        font-size: 16px;
        padding-bottom: 10px;
    }
}

.successResponse {
    color: map-get($ford-other-color, green-1);
}

failureResponse {
    color: map-get($ford-action-color, red-color);
}

.stepperOptionalLabel {
    align-items: flex-start;
}

.serviceOptionalLabel {
    .container {
        display: flex;
        align-items: center;
        margin: 0;

        .stepTitle {
            color: map-get($ford-element-color, 1200);
            margin: 0;
        }

        .stepValue {
            color: map-get($ford-other-color, gray-2);
            margin: 0;
        }
    }
}

.serviceStepperContent {
    margin-bottom: 10px;

    .stepRequestField {
        font-size: 16px;
        margin: 0;
        color: map-get($ford-other-color, gray-2);

        .stepKey {
            color: map-get($ford-element-color, 1200);
        }
    }

    .innerContainer {
        margin: 0;
    }

    .notesContainer {
        margin: 0;
        align-items: center;
    }
}

.services_stepper {
    background: #FFFFFF;
    border: 1px solid #BFCDD9;
    border-radius: 4px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
    padding: 20px;
    gap: 28px;
}

.serviceStepperSubmit {
    display: flex;
    align-items: center;
    column-gap: 10px;

    .nextButton {
        background: #073C88;
    }

    .backButton {
        color: map-get($ford-action-color, default-1);
        border: 1px solid #BFCDD9 
    }

    .selectedChargers {
        margin: 0;
    }
}

.stepperEditButton {
    color: map-get($ford-action-color, default-1);
    border: 1px solid #BFCDD9;
    margin-bottom: 10px;
}

.stepperResetButton {
    background: map-get($ford-primary-color, 12);
}

.servicesPage {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .servicesRedirects {
        display: flex;
        column-gap: 10px;
    
        .historyButton {
            border: 1px solid map-get($ford-action-color, default-1);
        }
    }
}

#cancelScheduledServices {
    color: #C83E32;
    border: 1px solid #C83E32;
}

.rfidInfo {
    display: flex;
    align-items: flex-start;
    padding: 16px;
    column-gap: 5px;
    background: #FFFFFF;
    border: 1px solid #D6D7DE;
    border-radius: 8px;

    .infoIcon {
        color:#22539B;
    }

    .infoText {
        margin: 0;
        color: #38393F;
        font-weight: 600;
        font-size: 14px;
    }
}

.rfidDownloadTemplate {
    display: flex;
    align-items: center;
    color: #0064D4;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
}

.serviceSidebarBody {
    width: 100%;
    height: calc(100vh - 160px);
}

.rfidInfoContainer {
    height: 100%;
    margin: 20px 10px;
}

.uploadRfidCardPreview {
    height: 500px;
    overflow-y: scroll;
}

.serviceSidebarActionBtns {
    display: flex;
    column-gap: 10px;
    justify-content: right;
    background: #FFFFFF;
    border: 1px solid #D6D7DE;
    padding: 10px;
    position: sticky;
    bottom: 0;
    right: 0;

    .cancelBtn {
        color: #073C88;
        font-size: 14px;
    }

    .addCardBtn {
        background: #073C88;
        color: #EFF2F5;
        font-size: 14px;

        &:disabled {
            background: #A3B6C7;
        }
    }
}

.lastAttemptedDate {
    display: flex;
    align-items: center;
    column-gap: 5px;
    margin: 0;
    color: #38393F;

    .icon {
        color: #38393F;
        cursor: pointer;
    }
}

.lastCommunicationTime {
    margin: 0;
    color: #38393F;
}

.labelElement {
    display: flex;
    padding-top: 10px;
}

.requestBtn {
    border: 1px solid #073C88 !important;
    color: #073C88 !important;
    width: 100%;
}